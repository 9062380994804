<template>
	<div>
		<div class="boardForm">
			<div class="bf_listDiv" v-for="(item,index) in $t('factoryDisplay.iaminatedStructure.list')" :key="index">
				<div class="bf_ld_title">{{item.title}}
					<span class="bf_ld_tl_notes">{{item.notes}}</span>
				</div>
				<div class="bf_ld_nav">
					<div v-for="(item2,index2) in item.dataList" :key="index2" @click="navClick(index,index2)">
						<div class="bf_ld_nav_name"
							:style="item.navIndex == index2 ? 'background: #dd102d;color: #fff;' : ''">{{item2.name}}
						</div>
					</div>
				</div>
				
				<div v-for="(item3,index3) in item.dataList" :key="index3">
					<div v-if="item.navIndex == index3" style="overflow-x: scroll;overflow-y: hidden;">
						<!-- 表单 -->
						<table class="bf_ld_table">
							<tbody class="bf_ld_tb_tbody">
								<tr class="bf_ld_tb_to_tr" v-for="(item4,index4) in item3.text" :key="index4">
									<td class="bf_ld_tb_to_tr_td1">{{item4.title}}</td>
									<td class="bf_ld_tb_to_tr_td2" :style="item4.type==true ? 'color:#dd102d;' : ''">{{item4.text}}</td>
									<td class="bf_ld_tb_to_tr_td1">mm</td>
								</tr>
							</tbody>
						</table>
						
						<!-- 展示图 -->
						<div class="bf_ld_structuralDiagram">
							<div v-if="index == 0">
								<div class="bf_ld_sd_img">
									<div class="bf_ld_sd_img_wick thick">
										<div class="wickName">
											L1-Cu
										</div>
									</div>
								
									<div class="bf_ld_sd_img_wick2 blue">
										<div class="wickName">
											PP
										</div>
									</div>
								
									<div class="bf_ld_sd_img_wickScheme">
										<div class="wickName" style="position: absolute;top: 0;left: 0;z-index: 1;">
											L2-Cu
										</div>
								
										<div class="sample" style="background: #ffac75;">
											<span style="margin-right: 15px;">CORE</span>
											<span >{{item3.core}}</span>
										</div>
										
										<div class="wickName" style="position: absolute;bottom: 1px;left: 0;z-index: 1;">
											L3-Cu
										</div>
									</div>
									
									<div class="bf_ld_sd_img_wick2 blue" style="padding-bottom: 20px;">
										<div class="wickName">
											PP
										</div>
									</div>
									
									<div class="bf_ld_sd_img_wick3 thick">
										<div class="wickName">
											L4-Cu
										</div>
									</div>
								</div>
							</div>
							
							<div v-if="index == 1">
								<div class="bf_ld_sd_img">
									<div class="bf_ld_sd_img_wick thick">
										<div class="wickName">
											L1-Cu
										</div>
									</div>
								
									<div class="bf_ld_sd_img_wick2 blue">
										<div class="wickName">
											PP
										</div>
									</div>
								
									<div class="bf_ld_sd_img_wickScheme">
										<div class="wickName" style="position: absolute;top: 0;left: 0;z-index: 1;">
											L2-Cu
										</div>
								
										<div class="sample" style="background: #fd7a80;">
											<span style="margin-right: 15px;">CORE</span>
											<span >{{item3.core}}</span>
										</div>
										
										<div class="wickName" style="position: absolute;bottom: 1px;left: 0;z-index: 1;">
											L3-Cu
										</div>
									</div>
									
									<div class="bf_ld_sd_img_wick2 blue">
										<div class="wickName">
											PP
										</div>
									</div>
									
									
									<div class="bf_ld_sd_img_wickScheme">
										<div class="wickName" style="position: absolute;top: 0;left: 0;z-index: 1;">
											L4-Cu
										</div>
																	
										<div class="sample" style="background: #fd7a80;">
											<span style="margin-right: 15px;">CORE</span>
											<span >{{item3.core}}</span>
										</div>
										
										<div class="wickName" style="position: absolute;bottom: 1px;left: 0;z-index: 1;">
											L5-Cu
										</div>
									</div>
									
									<div class="bf_ld_sd_img_wick2 blue" style="padding-bottom: 20px;">
										<div class="wickName">
											PP
										</div>
									</div>
									
									<div class="bf_ld_sd_img_wick3 thick">
										<div class="wickName">
											L6-Cu
										</div>
									</div>
								</div>
							</div>
							
							<div v-if="index == 2">
								<div class="bf_ld_sd_img">
									<div class="bf_ld_sd_img_wick thick">
										<div class="wickName">
											L1-Cu
										</div>
									</div>
								
									<div class="bf_ld_sd_img_wick2 blue">
										<div class="wickName">
											PP
										</div>
									</div>
								
									<div class="bf_ld_sd_img_wickScheme">
										<div class="wickName" style="position: absolute;top: 0;left: 0;z-index: 1;">
											L2-Cu
										</div>
								
										<div class="sample" style="background: #58c0e4;">
											<span style="margin-right: 15px;">CORE</span>
											<span >{{item3.core}}</span>
										</div>
										
										<div class="wickName" style="position: absolute;bottom: 1px;left: 0;z-index: 1;">
											L3-Cu
										</div>
									</div>
									
									<div class="bf_ld_sd_img_wick2 blue">
										<div class="wickName">
											PP
										</div>
									</div>
									
									
									<div class="bf_ld_sd_img_wickScheme">
										<div class="wickName" style="position: absolute;top: 0;left: 0;z-index: 1;">
											L4-Cu
										</div>
																	
										<div class="sample" style="background: #58c0e4;">
											<span style="margin-right: 15px;">CORE</span>
											<span >{{item3.core}}</span>
										</div>
										
										<div class="wickName" style="position: absolute;bottom: 1px;left: 0;z-index: 1;">
											L5-Cu
										</div>
									</div>
									
									<div class="bf_ld_sd_img_wick2 blue" style="padding-bottom: 20px;">
										<div class="wickName">
											PP
										</div>
									</div>
									
									<div class="bf_ld_sd_img_wickScheme">
										<div class="wickName" style="position: absolute;top: 0;left: 0;z-index: 1;">
											L6-Cu
										</div>
																	
										<div class="sample" style="background: #58c0e4;">
											<span style="margin-right: 15px;">CORE</span>
											<span >{{item3.core}}</span>
										</div>
										
										<div class="wickName" style="position: absolute;bottom: 1px;left: 0;z-index: 1;">
											L7-Cu
										</div>
									</div>
									
									<div class="bf_ld_sd_img_wick2 blue" style="padding-bottom: 20px;">
										<div class="wickName">
											PP
										</div>
									</div>
									
									<div class="bf_ld_sd_img_wick3 thick">
										<div class="wickName">
											L8-Cu
										</div>
									</div>
								</div>
							</div>
							
							<div v-if="index == 3">
								<div class="bf_ld_sd_img">
									<div class="bf_ld_sd_img_wick thick">
										<div class="wickName">
											L1-Cu
										</div>
									</div>
								
									<div class="bf_ld_sd_img_wick2 blue">
										<div class="wickName">
											PP
										</div>
									</div>
								
									<div class="bf_ld_sd_img_wickScheme">
										<div class="wickName" style="position: absolute;top: 0;left: 0;z-index: 1;">
											L2-Cu
										</div>
								
										<div class="sample" style="background: #31cb98;">
											<span style="margin-right: 15px;">CORE</span>
											<span >{{item3.core}}</span>
										</div>
										
										<div class="wickName" style="position: absolute;bottom: 1px;left: 0;z-index: 1;">
											L3-Cu
										</div>
									</div>
									
									<div class="bf_ld_sd_img_wick2 blue">
										<div class="wickName">
											PP
										</div>
									</div>
									
									
									<div class="bf_ld_sd_img_wickScheme">
										<div class="wickName" style="position: absolute;top: 0;left: 0;z-index: 1;">
											L4-Cu
										</div>
																	
										<div class="sample" style="background: #31cb98;">
											<span style="margin-right: 15px;">CORE</span>
											<span >{{item3.core}}</span>
										</div>
										
										<div class="wickName" style="position: absolute;bottom: 1px;left: 0;z-index: 1;">
											L5-Cu
										</div>
									</div>
									
									<div class="bf_ld_sd_img_wick2 blue" style="padding-bottom: 20px;">
										<div class="wickName">
											PP
										</div>
									</div>
									
									<div class="bf_ld_sd_img_wickScheme">
										<div class="wickName" style="position: absolute;top: 0;left: 0;z-index: 1;">
											L6-Cu
										</div>
																	
										<div class="sample" style="background: #31cb98;">
											<span style="margin-right: 15px;">CORE</span>
											<span >{{item3.core}}</span>
										</div>
										
										<div class="wickName" style="position: absolute;bottom: 1px;left: 0;z-index: 1;">
											L7-Cu
										</div>
									</div>
									
									<div class="bf_ld_sd_img_wick2 blue" style="padding-bottom: 20px;">
										<div class="wickName">
											PP
										</div>
									</div>
									
									<div class="bf_ld_sd_img_wickScheme">
										<div class="wickName" style="position: absolute;top: 0;left: 0;z-index: 1;">
											L8-Cu
										</div>
																	
										<div class="sample" style="background: #31cb98;">
											<span style="margin-right: 15px;">CORE</span>
											<span >{{item3.core}}</span>
										</div>
										
										<div class="wickName" style="position: absolute;bottom: 1px;left: 0;z-index: 1;">
											L9-Cu
										</div>
									</div>
									
									<div class="bf_ld_sd_img_wick2 blue" style="padding-bottom: 20px;">
										<div class="wickName">
											PP
										</div>
									</div>
									
									<div class="bf_ld_sd_img_wick3 thick">
										<div class="wickName">
											L10-Cu
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				list: [{
					title: "四层板压合结构图",
					notes: "（不含电镀/阻焊/字符/表面工艺厚度）",
					navIndex: 0, //nav下标
					dataList: [{
						core: "0.13mm",
						name: "0.4mm",
						text: [{
								title: "L1-Cu:",
								text: "0.0175 (0.5oz)",
								type: false
							},
							{
								title: "PP:",
								text: "0.08 (1080)",
								type: false
							},
							{
								title: "L2-Cu:",
								text: "0.035 (1oz)",
								type: false
							},
							{
								title: "CORE:",
								text: "0.13",
								type: false
							},
							{
								title: "L3-Cu:",
								text: "0.035 (1oz)",
								type: false
							},
							{
								title: "PP:",
								text: "0.08 (1080)",
								type: false
							},
							{
								title: "L4-Cu:",
								text: "0.0175 (0.5oz)",
								type: false
							},
							{
								title: "Finish:",
								text: "0.4 +/-0.13mm",
								type: true
							}
						]
					}, {
						core: "0.13mm",
						name: "0.6mm",
						text: [{
								title: "L1-Cu:",
								text: "0.0175 (0.5oz)",
								type: false
							},
							{
								title: "PP:",
								text: "0.12 (2116)",
								type: false
							},
							{
								title: "L2-Cu:",
								text: "0.035 (1oz)",
								type: false
							},
							{
								title: "CORE:",
								text: "0.13",
								type: false
							},
							{
								title: "L3-Cu:",
								text: "0.035 (1oz)",
								type: false
							},
							{
								title: "PP:",
								text: "0.12 (2116)",
								type: false
							},
							{
								title: "L4-Cu:",
								text: "0.0175 (0.5oz)",
								type: false
							},
							{
								title: "Finish:",
								text: "0.6 +/-0.13mm",
								type: true
							}
						]
					}]
				}]
			}
		},

		created() {

		},

		mounted() {

		},

		watch: {

		},

		computed: {

		},

		methods: {
			//--------------------nav下标-----------------------------
			navClick: function(index, index2) {
				// console.log(this.$t('factoryDisplay.iaminatedStructure.list'))
				this.$t('factoryDisplay.iaminatedStructure.list')[index].navIndex = index2;
			}
		}
	}
</script>

<style lang="less" scoped>
	
	@media only screen and (min-width:1920px) {
		.boardForm {
			padding: 40px 200px;
		
			.bf_listDiv {
				background: #fff;
				padding: 20px 40px;
		
				.bf_ld_title {
					font-size: 24px;
					color: #333333;
					font-weight: bold;
		
					.bf_ld_tl_notes {
						color: #dd102d;
						font-size: 16px;
					}
				}
		
				.bf_ld_nav {
					border-radius: 3px;
					padding-top: 20px;
					overflow: hidden;
					display: flex;
					flex-wrap: wrap;
		
					.bf_ld_nav_name {
						height: 30px;
						line-height: 30px;
						padding: 0 16px;
						background-color: #ffffff;
						font-size: 16px;
						border: 1px solid #e3e3e3;
						color: #333333;
						cursor: pointer;
					}
		
					.bf_ld_nav_name:hover {
						background-color: #e3e3e3;
					}
		
				}
		
				.bf_ld_table {
					width: 100%;
					border-collapse: collapse;
					margin-top: 20px;
		
					.bf_ld_tb_tbody {
						display: table-row-group;
						vertical-align: middle;
						border-color: inherit;
		
						.bf_ld_tb_to_tr {
							display: table-row;
							vertical-align: inherit;
							border-color: inherit;
		
							.bf_ld_tb_to_tr_td1 {
								text-align: left;
								padding-right: 30px;
								background: #f7f7f7;
								font-size: 15px;
								padding-left: 0;
								border: 1px solid #e3e3e3;
								padding: 10px 20px;
							}
		
							.bf_ld_tb_to_tr_td2 {
								text-align: left;
								padding-right: 30px;
								background: #fff;
								font-size: 15px;
								padding-left: 0;
								border: 1px solid #e3e3e3;
								padding: 10px 20px;
							}
						}
					}
				}
		
				.bf_ld_structuralDiagram {
					padding: 40px;
					display: flex;
					justify-content: center;
					border-left: 1px solid #e3e3e3;
					border-right: 1px solid #e3e3e3;
					border-bottom: 1px solid #e3e3e3;
		
					.bf_ld_sd_img {
						width: 420px;
						padding: 30px 0;
						box-sizing: border-box;
		
						.bf_ld_sd_img_wick {
							position: relative;
							height: 20px;
							margin-bottom: 8px;
						}
		
						.bf_ld_sd_img_wick2 {
							position: relative;
							height: 20px;
							margin-bottom: 8px;
		
						}
						
						.bf_ld_sd_img_wick3 {
							position: relative;
							height: 20px;
							margin-bottom: 8px;
						}
		
						.bf_ld_sd_img_wickScheme {
							position: relative;
							// height: 20px;
							margin-bottom: 8px;
							padding: 10px 0;
							height: auto;
						}
		
						.bf_ld_sd_img_wick:before {
							display: block;
							content: '';
							background: url(../../../../assets/img/thick.png) no-repeat center;
							width: 350px;
							height: 12px;
							position: absolute;
							top: -6px;
							right: 0;
							z-index: 1;
						}
						
						.bf_ld_sd_img_wick3:before {
							display: block;
							content: '';
							background: url(../../../../assets/img/thick.png) no-repeat center;
							width: 350px;
							height: 12px;
							position: absolute;
							bottom: -6px;
							right: 0;
							z-index: 1;
						}
		
						.thick {}
		
						.blue {
							height: 20px;
						}
		
						.thick::after {
							display: block;
							content: '';
							border-top: 5px solid #0d4594;
							width: 350px;
							position: absolute;
							top: 50%;
							right: 0;
							z-index: 1;
							margin-top: -2px;
						}
		
						.blue::after {
							display: block;
							content: '';
							width: 350px;
							height: 15px;
							background-color: #333333;
							position: absolute;
							top: 50%;
							right: 0;
							margin-top: -7px;
							z-index: 1;
						}
					}
				}
			}
		}
		
		.wickName {
			width: 51px;
			height: 20px;
			line-height: 18px;
			background-color: #ffffff;
			border: solid 1px #333333;
			font-size: 14px;
			font-weight: bold;
			text-align: center;
			box-sizing: border-box;
			position: relative;
		}
		
		.sample {
			position: relative;
			z-index: 2;
			width: 350px;
			height: 60px;
			line-height: 54px;
			margin-left: 70px;
			text-align: center;
			font-size: 20px;
			border-top: 5px solid #0d4594;
			border-bottom: 5px solid #0d4594;
			box-sizing: border-box;
		}
		
		.wickName:before {
			display: block;
			content: '';
			background: url(../../../../assets/img/flag-icon.png) no-repeat center;
			width: 29px;
			height: 5px;
			position: absolute;
			top: 50%;
			margin-top: -2px;
			right: -27px;
			z-index: 1;
		}
	}
	
	@media only screen and (max-width:1920px) {
		.boardForm {
			padding: 40px 200px;
		
			.bf_listDiv {
				background: #fff;
				padding: 20px 40px;
		
				.bf_ld_title {
					font-size: 24px;
					color: #333333;
					font-weight: bold;
		
					.bf_ld_tl_notes {
						color: #dd102d;
						font-size: 16px;
					}
				}
		
				.bf_ld_nav {
					border-radius: 3px;
					padding-top: 20px;
					overflow: hidden;
					display: flex;
					flex-wrap: wrap;
		
					.bf_ld_nav_name {
						height: 30px;
						line-height: 30px;
						padding: 0 16px;
						background-color: #ffffff;
						font-size: 16px;
						border: 1px solid #e3e3e3;
						color: #333333;
						cursor: pointer;
					}
		
					.bf_ld_nav_name:hover {
						background-color: #e3e3e3;
					}
		
				}
		
				.bf_ld_table {
					width: 100%;
					border-collapse: collapse;
					margin-top: 20px;
		
					.bf_ld_tb_tbody {
						display: table-row-group;
						vertical-align: middle;
						border-color: inherit;
		
						.bf_ld_tb_to_tr {
							display: table-row;
							vertical-align: inherit;
							border-color: inherit;
		
							.bf_ld_tb_to_tr_td1 {
								text-align: left;
								padding-right: 30px;
								background: #f7f7f7;
								font-size: 15px;
								padding-left: 0;
								border: 1px solid #e3e3e3;
								padding: 10px 20px;
							}
		
							.bf_ld_tb_to_tr_td2 {
								text-align: left;
								padding-right: 30px;
								background: #fff;
								font-size: 15px;
								padding-left: 0;
								border: 1px solid #e3e3e3;
								padding: 10px 20px;
							}
						}
					}
				}
		
				.bf_ld_structuralDiagram {
					padding: 40px;
					display: flex;
					justify-content: center;
					border-left: 1px solid #e3e3e3;
					border-right: 1px solid #e3e3e3;
					border-bottom: 1px solid #e3e3e3;
		
					.bf_ld_sd_img {
						width: 420px;
						padding: 30px 0;
						box-sizing: border-box;
		
						.bf_ld_sd_img_wick {
							position: relative;
							height: 20px;
							margin-bottom: 8px;
						}
		
						.bf_ld_sd_img_wick2 {
							position: relative;
							height: 20px;
							margin-bottom: 8px;
		
						}
						
						.bf_ld_sd_img_wick3 {
							position: relative;
							height: 20px;
							margin-bottom: 8px;
						}
		
						.bf_ld_sd_img_wickScheme {
							position: relative;
							// height: 20px;
							margin-bottom: 8px;
							padding: 10px 0;
							height: auto;
						}
		
						.bf_ld_sd_img_wick:before {
							display: block;
							content: '';
							background: url(../../../../assets/img/thick.png) no-repeat center;
							width: 350px;
							height: 12px;
							position: absolute;
							top: -6px;
							right: 0;
							z-index: 1;
						}
						
						.bf_ld_sd_img_wick3:before {
							display: block;
							content: '';
							background: url(../../../../assets/img/thick.png) no-repeat center;
							width: 350px;
							height: 12px;
							position: absolute;
							bottom: -6px;
							right: 0;
							z-index: 1;
						}
		
						.thick {}
		
						.blue {
							height: 20px;
						}
		
						.thick::after {
							display: block;
							content: '';
							border-top: 5px solid #0d4594;
							width: 350px;
							position: absolute;
							top: 50%;
							right: 0;
							z-index: 1;
							margin-top: -2px;
						}
		
						.blue::after {
							display: block;
							content: '';
							width: 350px;
							height: 15px;
							background-color: #333333;
							position: absolute;
							top: 50%;
							right: 0;
							margin-top: -7px;
							z-index: 1;
						}
					}
				}
			}
		}
		
		.wickName {
			width: 51px;
			height: 20px;
			line-height: 18px;
			background-color: #ffffff;
			border: solid 1px #333333;
			font-size: 14px;
			font-weight: bold;
			text-align: center;
			box-sizing: border-box;
			position: relative;
		}
		
		.sample {
			position: relative;
			z-index: 2;
			width: 350px;
			height: 60px;
			line-height: 54px;
			margin-left: 70px;
			text-align: center;
			font-size: 20px;
			border-top: 5px solid #0d4594;
			border-bottom: 5px solid #0d4594;
			box-sizing: border-box;
		}
		
		.wickName:before {
			display: block;
			content: '';
			background: url(../../../../assets/img/flag-icon.png) no-repeat center;
			width: 29px;
			height: 5px;
			position: absolute;
			top: 50%;
			margin-top: -2px;
			right: -27px;
			z-index: 1;
		}
	}
	
	@media only screen and (max-width:1919px) and (min-width:1440px) {
		.boardForm {
			padding: 40px 140px;
		
			.bf_listDiv {
				background: #fff;
				padding: 20px 40px;
		
				.bf_ld_title {
					font-size: 24px;
					color: #333333;
					font-weight: bold;
		
					.bf_ld_tl_notes {
						color: #dd102d;
						font-size: 16px;
					}
				}
		
				.bf_ld_nav {
					border-radius: 3px;
					padding-top: 20px;
					overflow: hidden;
					display: flex;
					flex-wrap: wrap;
		
					.bf_ld_nav_name {
						height: 30px;
						line-height: 30px;
						padding: 0 16px;
						background-color: #ffffff;
						font-size: 16px;
						border: 1px solid #e3e3e3;
						color: #333333;
						cursor: pointer;
					}
		
					.bf_ld_nav_name:hover {
						background-color: #e3e3e3;
					}
		
				}
		
				.bf_ld_table {
					width: 100%;
					border-collapse: collapse;
					margin-top: 20px;
		
					.bf_ld_tb_tbody {
						display: table-row-group;
						vertical-align: middle;
						border-color: inherit;
		
						.bf_ld_tb_to_tr {
							display: table-row;
							vertical-align: inherit;
							border-color: inherit;
		
							.bf_ld_tb_to_tr_td1 {
								text-align: left;
								padding-right: 30px;
								background: #f7f7f7;
								font-size: 15px;
								padding-left: 0;
								border: 1px solid #e3e3e3;
								padding: 10px 20px;
							}
		
							.bf_ld_tb_to_tr_td2 {
								text-align: left;
								padding-right: 30px;
								background: #fff;
								font-size: 15px;
								padding-left: 0;
								border: 1px solid #e3e3e3;
								padding: 10px 20px;
							}
						}
					}
				}
		
				.bf_ld_structuralDiagram {
					padding: 40px;
					display: flex;
					justify-content: center;
					border-left: 1px solid #e3e3e3;
					border-right: 1px solid #e3e3e3;
					border-bottom: 1px solid #e3e3e3;
		
					.bf_ld_sd_img {
						width: 420px;
						padding: 30px 0;
						box-sizing: border-box;
		
						.bf_ld_sd_img_wick {
							position: relative;
							height: 20px;
							margin-bottom: 8px;
						}
		
						.bf_ld_sd_img_wick2 {
							position: relative;
							height: 20px;
							margin-bottom: 8px;
		
						}
						
						.bf_ld_sd_img_wick3 {
							position: relative;
							height: 20px;
							margin-bottom: 8px;
						}
		
						.bf_ld_sd_img_wickScheme {
							position: relative;
							// height: 20px;
							margin-bottom: 8px;
							padding: 10px 0;
							height: auto;
						}
		
						.bf_ld_sd_img_wick:before {
							display: block;
							content: '';
							background: url(../../../../assets/img/thick.png) no-repeat center;
							width: 350px;
							height: 12px;
							position: absolute;
							top: -6px;
							right: 0;
							z-index: 1;
						}
						
						.bf_ld_sd_img_wick3:before {
							display: block;
							content: '';
							background: url(../../../../assets/img/thick.png) no-repeat center;
							width: 350px;
							height: 12px;
							position: absolute;
							bottom: -6px;
							right: 0;
							z-index: 1;
						}
		
						.thick {}
		
						.blue {
							height: 20px;
						}
		
						.thick::after {
							display: block;
							content: '';
							border-top: 5px solid #0d4594;
							width: 350px;
							position: absolute;
							top: 50%;
							right: 0;
							z-index: 1;
							margin-top: -2px;
						}
		
						.blue::after {
							display: block;
							content: '';
							width: 350px;
							height: 15px;
							background-color: #333333;
							position: absolute;
							top: 50%;
							right: 0;
							margin-top: -7px;
							z-index: 1;
						}
					}
				}
			}
		}
		
		.wickName {
			width: 51px;
			height: 20px;
			line-height: 18px;
			background-color: #ffffff;
			border: solid 1px #333333;
			font-size: 14px;
			font-weight: bold;
			text-align: center;
			box-sizing: border-box;
			position: relative;
		}
		
		.sample {
			position: relative;
			z-index: 2;
			width: 350px;
			height: 60px;
			line-height: 54px;
			margin-left: 70px;
			text-align: center;
			font-size: 20px;
			border-top: 5px solid #0d4594;
			border-bottom: 5px solid #0d4594;
			box-sizing: border-box;
		}
		
		.wickName:before {
			display: block;
			content: '';
			background: url(../../../../assets/img/flag-icon.png) no-repeat center;
			width: 29px;
			height: 5px;
			position: absolute;
			top: 50%;
			margin-top: -2px;
			right: -27px;
			z-index: 1;
		}
	}
	
	@media only screen and (max-width:1439px) and (min-width:1200px) {
		.boardForm {
			padding: 40px 30px;
		
			.bf_listDiv {
				background: #fff;
				padding: 20px 40px;
		
				.bf_ld_title {
					font-size: 24px;
					color: #333333;
					font-weight: bold;
		
					.bf_ld_tl_notes {
						color: #dd102d;
						font-size: 16px;
					}
				}
		
				.bf_ld_nav {
					border-radius: 3px;
					padding-top: 20px;
					overflow: hidden;
					display: flex;
					flex-wrap: wrap;
		
					.bf_ld_nav_name {
						height: 30px;
						line-height: 30px;
						padding: 0 16px;
						background-color: #ffffff;
						font-size: 16px;
						border: 1px solid #e3e3e3;
						color: #333333;
						cursor: pointer;
					}
		
					.bf_ld_nav_name:hover {
						background-color: #e3e3e3;
					}
		
				}
		
				.bf_ld_table {
					width: 100%;
					border-collapse: collapse;
					margin-top: 20px;
		
					.bf_ld_tb_tbody {
						display: table-row-group;
						vertical-align: middle;
						border-color: inherit;
		
						.bf_ld_tb_to_tr {
							display: table-row;
							vertical-align: inherit;
							border-color: inherit;
		
							.bf_ld_tb_to_tr_td1 {
								text-align: left;
								padding-right: 30px;
								background: #f7f7f7;
								font-size: 15px;
								padding-left: 0;
								border: 1px solid #e3e3e3;
								padding: 10px 20px;
							}
		
							.bf_ld_tb_to_tr_td2 {
								text-align: left;
								padding-right: 30px;
								background: #fff;
								font-size: 15px;
								padding-left: 0;
								border: 1px solid #e3e3e3;
								padding: 10px 20px;
							}
						}
					}
				}
		
				.bf_ld_structuralDiagram {
					padding: 40px;
					display: flex;
					justify-content: center;
					border-left: 1px solid #e3e3e3;
					border-right: 1px solid #e3e3e3;
					border-bottom: 1px solid #e3e3e3;
		
					.bf_ld_sd_img {
						width: 420px;
						padding: 30px 0;
						box-sizing: border-box;
		
						.bf_ld_sd_img_wick {
							position: relative;
							height: 20px;
							margin-bottom: 8px;
						}
		
						.bf_ld_sd_img_wick2 {
							position: relative;
							height: 20px;
							margin-bottom: 8px;
		
						}
						
						.bf_ld_sd_img_wick3 {
							position: relative;
							height: 20px;
							margin-bottom: 8px;
						}
		
						.bf_ld_sd_img_wickScheme {
							position: relative;
							// height: 20px;
							margin-bottom: 8px;
							padding: 10px 0;
							height: auto;
						}
		
						.bf_ld_sd_img_wick:before {
							display: block;
							content: '';
							background: url(../../../../assets/img/thick.png) no-repeat center;
							width: 350px;
							height: 12px;
							position: absolute;
							top: -6px;
							right: 0;
							z-index: 1;
						}
						
						.bf_ld_sd_img_wick3:before {
							display: block;
							content: '';
							background: url(../../../../assets/img/thick.png) no-repeat center;
							width: 350px;
							height: 12px;
							position: absolute;
							bottom: -6px;
							right: 0;
							z-index: 1;
						}
		
						.thick {}
		
						.blue {
							height: 20px;
						}
		
						.thick::after {
							display: block;
							content: '';
							border-top: 5px solid #0d4594;
							width: 350px;
							position: absolute;
							top: 50%;
							right: 0;
							z-index: 1;
							margin-top: -2px;
						}
		
						.blue::after {
							display: block;
							content: '';
							width: 350px;
							height: 15px;
							background-color: #333333;
							position: absolute;
							top: 50%;
							right: 0;
							margin-top: -7px;
							z-index: 1;
						}
					}
				}
			}
		}
		
		.wickName {
			width: 51px;
			height: 20px;
			line-height: 18px;
			background-color: #ffffff;
			border: solid 1px #333333;
			font-size: 14px;
			font-weight: bold;
			text-align: center;
			box-sizing: border-box;
			position: relative;
		}
		
		.sample {
			position: relative;
			z-index: 2;
			width: 350px;
			height: 60px;
			line-height: 54px;
			margin-left: 70px;
			text-align: center;
			font-size: 20px;
			border-top: 5px solid #0d4594;
			border-bottom: 5px solid #0d4594;
			box-sizing: border-box;
		}
		
		.wickName:before {
			display: block;
			content: '';
			background: url(../../../../assets/img/flag-icon.png) no-repeat center;
			width: 29px;
			height: 5px;
			position: absolute;
			top: 50%;
			margin-top: -2px;
			right: -27px;
			z-index: 1;
		}
	}
	
	@media only screen and (max-width:1199px) and (min-width:768px) {
		.boardForm {
			padding: 40px 30px;
		
			.bf_listDiv {
				background: #fff;
				padding: 20px 40px;
		
				.bf_ld_title {
					font-size: 24px;
					color: #333333;
					font-weight: bold;
		
					.bf_ld_tl_notes {
						color: #dd102d;
						font-size: 16px;
					}
				}
		
				.bf_ld_nav {
					border-radius: 3px;
					padding-top: 20px;
					overflow: hidden;
					display: flex;
					flex-wrap: wrap;
		
					.bf_ld_nav_name {
						height: 30px;
						line-height: 30px;
						padding: 0 16px;
						background-color: #ffffff;
						font-size: 16px;
						border: 1px solid #e3e3e3;
						color: #333333;
						cursor: pointer;
					}
		
					.bf_ld_nav_name:hover {
						background-color: #e3e3e3;
					}
		
				}
		
				.bf_ld_table {
					width: 100%;
					border-collapse: collapse;
					margin-top: 20px;
		
					.bf_ld_tb_tbody {
						display: table-row-group;
						vertical-align: middle;
						border-color: inherit;
		
						.bf_ld_tb_to_tr {
							display: table-row;
							vertical-align: inherit;
							border-color: inherit;
		
							.bf_ld_tb_to_tr_td1 {
								text-align: left;
								padding-right: 30px;
								background: #f7f7f7;
								font-size: 15px;
								padding-left: 0;
								border: 1px solid #e3e3e3;
								padding: 10px 20px;
							}
		
							.bf_ld_tb_to_tr_td2 {
								text-align: left;
								padding-right: 30px;
								background: #fff;
								font-size: 15px;
								padding-left: 0;
								border: 1px solid #e3e3e3;
								padding: 10px 20px;
							}
						}
					}
				}
		
				.bf_ld_structuralDiagram {
					display: flex;
					justify-content: center;
					border-left: 1px solid #e3e3e3;
					border-right: 1px solid #e3e3e3;
					border-bottom: 1px solid #e3e3e3;
		
					.bf_ld_sd_img {
						width: 420px;
						padding: 30px 0;
						box-sizing: border-box;
		
						.bf_ld_sd_img_wick {
							position: relative;
							height: 20px;
							margin-bottom: 8px;
						}
		
						.bf_ld_sd_img_wick2 {
							position: relative;
							height: 20px;
							margin-bottom: 8px;
		
						}
						
						.bf_ld_sd_img_wick3 {
							position: relative;
							height: 20px;
							margin-bottom: 8px;
						}
		
						.bf_ld_sd_img_wickScheme {
							position: relative;
							// height: 20px;
							margin-bottom: 8px;
							padding: 10px 0;
							height: auto;
						}
		
						.bf_ld_sd_img_wick:before {
							display: block;
							content: '';
							background: url(../../../../assets/img/thick.png) no-repeat center;
							width: 350px;
							height: 12px;
							position: absolute;
							top: -6px;
							right: 0;
							z-index: 1;
						}
						
						.bf_ld_sd_img_wick3:before {
							display: block;
							content: '';
							background: url(../../../../assets/img/thick.png) no-repeat center;
							width: 350px;
							height: 12px;
							position: absolute;
							bottom: -6px;
							right: 0;
							z-index: 1;
						}
		
						.thick {}
		
						.blue {
							height: 20px;
						}
		
						.thick::after {
							display: block;
							content: '';
							border-top: 5px solid #0d4594;
							width: 350px;
							position: absolute;
							top: 50%;
							right: 0;
							z-index: 1;
							margin-top: -2px;
						}
		
						.blue::after {
							display: block;
							content: '';
							width: 350px;
							height: 15px;
							background-color: #333333;
							position: absolute;
							top: 50%;
							right: 0;
							margin-top: -7px;
							z-index: 1;
						}
					}
				}
			}
		}
		
		.wickName {
			width: 51px;
			height: 20px;
			line-height: 18px;
			background-color: #ffffff;
			border: solid 1px #333333;
			font-size: 14px;
			font-weight: bold;
			text-align: center;
			box-sizing: border-box;
			position: relative;
		}
		
		.sample {
			position: relative;
			z-index: 2;
			width: 350px;
			height: 60px;
			line-height: 54px;
			margin-left: 70px;
			text-align: center;
			font-size: 20px;
			border-top: 5px solid #0d4594;
			border-bottom: 5px solid #0d4594;
			box-sizing: border-box;
		}
		
		.wickName:before {
			display: block;
			content: '';
			background: url(../../../../assets/img/flag-icon.png) no-repeat center;
			width: 29px;
			height: 5px;
			position: absolute;
			top: 50%;
			margin-top: -2px;
			right: -27px;
			z-index: 1;
		}
	}
	
	@media only screen and (max-width:767px) {
		.boardForm {
			padding: 40px 30px;
		
			.bf_listDiv {
				background: #fff;
				padding: 20px;
		
				.bf_ld_title {
					font-size: 24px;
					color: #333333;
					font-weight: bold;
		
					.bf_ld_tl_notes {
						color: #dd102d;
						font-size: 16px;
					}
				}
		
				.bf_ld_nav {
					border-radius: 3px;
					padding-top: 20px;
					overflow: hidden;
					display: flex;
					flex-wrap: wrap;
		
					.bf_ld_nav_name {
						height: 30px;
						line-height: 30px;
						padding: 0 16px;
						background-color: #ffffff;
						font-size: 16px;
						border: 1px solid #e3e3e3;
						color: #333333;
						cursor: pointer;
					}
		
					.bf_ld_nav_name:hover {
						background-color: #e3e3e3;
					}
		
				}
		
				.bf_ld_table {
					width: 100%;
					border-collapse: collapse;
					margin-top: 20px;
		
					.bf_ld_tb_tbody {
						display: table-row-group;
						vertical-align: middle;
						border-color: inherit;
		
						.bf_ld_tb_to_tr {
							display: table-row;
							vertical-align: inherit;
							border-color: inherit;
		
							.bf_ld_tb_to_tr_td1 {
								text-align: left;
								padding-right: 30px;
								background: #f7f7f7;
								font-size: 15px;
								padding-left: 0;
								border: 1px solid #e3e3e3;
								padding: 10px 20px;
							}
		
							.bf_ld_tb_to_tr_td2 {
								text-align: left;
								padding-right: 30px;
								background: #fff;
								font-size: 15px;
								padding-left: 0;
								border: 1px solid #e3e3e3;
								padding: 10px 20px;
							}
						}
					}
				}
		
				.bf_ld_structuralDiagram {
					display: flex;
					justify-content: center;
					border-left: 1px solid #e3e3e3;
					border-right: 1px solid #e3e3e3;
					border-bottom: 1px solid #e3e3e3;
		
					.bf_ld_sd_img {
						width: 100%;
						padding: 30px 0;
						box-sizing: border-box;
		
						.bf_ld_sd_img_wick {
							position: relative;
							height: 20px;
							margin-bottom: 8px;
						}
		
						.bf_ld_sd_img_wick2 {
							position: relative;
							height: 20px;
							margin-bottom: 8px;
		
						}
						
						.bf_ld_sd_img_wick3 {
							position: relative;
							height: 20px;
							margin-bottom: 8px;
						}
		
						.bf_ld_sd_img_wickScheme {
							position: relative;
							// height: 20px;
							margin-bottom: 8px;
							padding: 10px 0;
							height: auto;
						}
		
						.bf_ld_sd_img_wick:before {
							display: block;
							content: '';
							background: url(../../../../assets/img/thick.png) no-repeat center;
							width: 80%;
							height: 12px;
							position: absolute;
							top: -6px;
							right: 0;
							z-index: 1;
						}
						
						.bf_ld_sd_img_wick3:before {
							display: block;
							content: '';
							background: url(../../../../assets/img/thick.png) no-repeat center;
							width: 80%;
							height: 12px;
							position: absolute;
							bottom: -6px;
							right: 0;
							z-index: 1;
						}
		
						.thick {}
		
						.blue {
							height: 20px;
						}
		
						.thick::after {
							display: block;
							content: '';
							border-top: 5px solid #0d4594;
							width: 80%;
							position: absolute;
							top: 50%;
							right: 0;
							z-index: 1;
							margin-top: -2px;
						}
		
						.blue::after {
							display: block;
							content: '';
							width: 80%;
							height: 15px;
							background-color: #333333;
							position: absolute;
							top: 50%;
							right: 0;
							margin-top: -7px;
							z-index: 1;
						}
					}
				}
			}
		}
		
		.wickName {
			width: 51px;
			height: 20px;
			line-height: 18px;
			background-color: #ffffff;
			border: solid 1px #333333;
			font-size: 14px;
			font-weight: bold;
			text-align: center;
			box-sizing: border-box;
			position: relative;
		}
		
		.sample {
			position: relative;
			z-index: 2;
			width: 67%;
			height: 60px;
			line-height: 54px;
			margin-left: 70px;
			text-align: center;
			font-size: 20px;
			border-top: 5px solid #0d4594;
			border-bottom: 5px solid #0d4594;
			box-sizing: border-box;
		}
		
		.wickName:before {
			display: block;
			content: '';
			background: url(../../../../assets/img/flag-icon.png) no-repeat center;
			width: 29px;
			height: 5px;
			position: absolute;
			top: 50%;
			margin-top: -2px;
			right: -27px;
			z-index: 1;
		}
	}
	
</style>
