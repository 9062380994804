<template>
	<div>
		<div class="banner" :style="'background:' + `url(${img1}) center`">
			<div class="bn_boardImg" :style="'background:' + `url(${img2}) no-repeat center 54px`">
				<div class="bn_bi_textImage" :style="'background:' + `url(${img3})`"></div>
			</div>
		</div>

		<div class="tis">{{$t('factoryDisplay.banner.title')}}</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				img1:process.env.VUE_APP_OSS_URL + '/solution/banner-bg.png',
				img2:process.env.VUE_APP_OSS_URL + '/solution/laminated-bg.png',
				img3:process.env.VUE_APP_OSS_URL + '/solution/banner-title.png',
			}
		},

		created() {

		},

		mounted() {

		},

		watch: {

		},

		computed: {

		},

		methods: {

		}
	}
</script>

<style lang="less" scoped>
	
	@media only screen and (min-width:1920px) {
		.banner {
			height: 211px;
			text-align: center;
			overflow: hidden;
		
			.bn_boardImg {
				position: relative;
				height: inherit;
				overflow: hidden;
		
				.bn_bi_textImage {
					display: block;
					width: 372px;
					height: 84px;
					margin: 14px auto 0;
				}
			}
		}
		
		.tis {
			padding: 10px 200px;
			border: 1px solid #ffdb8f;
			background-color: #fff8ea;
			font-size: 15px;
			margin-bottom: 20px;
			text-align: center;
		}
	}
	
	@media only screen and (max-width:1920px) {
		.banner {
			height: 211px;
			text-align: center;
			overflow: hidden;
		
			.bn_boardImg {
				position: relative;
				height: inherit;
				overflow: hidden;
		
				.bn_bi_textImage {
					display: block;
					width: 372px;
					height: 84px;
					margin: 14px auto 0;
				}
			}
		}
		
		.tis {
			padding: 10px 200px;
			border: 1px solid #ffdb8f;
			background-color: #fff8ea;
			font-size: 15px;
			margin-bottom: 20px;
			text-align: center;
		}
	}
	
	@media only screen and (max-width:1919px) and (min-width:1440px) {
		.banner {
			height: 211px;
			text-align: center;
			overflow: hidden;
		
			.bn_boardImg {
				position: relative;
				height: inherit;
				overflow: hidden;
		
				.bn_bi_textImage {
					display: block;
					width: 372px;
					height: 84px;
					margin: 14px auto 0;
				}
			}
		}
		
		.tis {
			padding: 10px 140px;
			border: 1px solid #ffdb8f;
			background-color: #fff8ea;
			font-size: 15px;
			margin-bottom: 20px;
			text-align: center;
		}
	}
	
	@media only screen and (max-width:1439px) and (min-width:1200px) {
		.banner {
			height: 211px;
			text-align: center;
			overflow: hidden;
		
			.bn_boardImg {
				position: relative;
				height: inherit;
				overflow: hidden;
		
				.bn_bi_textImage {
					display: block;
					width: 372px;
					height: 84px;
					margin: 14px auto 0;
				}
			}
		}
		
		.tis {
			padding: 10px 30px;
			border: 1px solid #ffdb8f;
			background-color: #fff8ea;
			font-size: 15px;
			margin-bottom: 20px;
			text-align: center;
		}
	}
	
	@media only screen and (max-width:1199px) and (min-width:768px) {
		.banner {
			margin-top: 60px;
			height: 211px;
			text-align: center;
			overflow: hidden;
		
			.bn_boardImg {
				position: relative;
				height: inherit;
				overflow: hidden;
		
				.bn_bi_textImage {
					display: block;
					width: 372px;
					height: 84px;
					margin: 14px auto 0;
				}
			}
		}
		
		.tis {
			padding: 10px 30px;
			border: 1px solid #ffdb8f;
			background-color: #fff8ea;
			font-size: 15px;
			margin-bottom: 20px;
			text-align: center;
		}
	}
	
	@media only screen and (max-width:767px) {
		.banner {
			margin-top: 60px;
			height: 211px;
			text-align: center;
			overflow: hidden;
		
			.bn_boardImg {
				position: relative;
				height: inherit;
				overflow: hidden;
		
				.bn_bi_textImage {
					display: block;
					width: 372px;
					height: 84px;
					margin: 14px auto 0;
				}
			}
		}
		
		.tis {
			padding: 10px 30px;
			border: 1px solid #ffdb8f;
			background-color: #fff8ea;
			font-size: 15px;
			margin-bottom: 20px;
			text-align: center;
		}
	}
	
</style>
